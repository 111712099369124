<template>

  <div class="clearfix" >
  <div class="zjIntro clearfix">
<!--    <h1 class="inteoH1">镇江一站式服务平台线上教程</h1>-->
    <div class="intro"><h3>重要说明</h3>
      <ul class="introtiplist">
        <li v-for="item in descriptionList" :key="item">{{item}}</li>
      </ul>
    </div>
    <dl class="introList">
      <dt><b style="color: #f60">【司机预约】</b>视频</dt>
      <dd v-for="item in videoList" :key="item.fileID">
        <div @click="playVideo(item)"><img  :src="require('../../assets/img/video.png')" alt="">{{ item.fileName }}</div></dd>
    </dl>
  </div>
  <div class="bg clearfix">
    <div class="bgbox clearfix"></div>
  </div>

  </div>

  <van-dialog v-model:show="showDialog" :title=title @closed="onCloseVideo">
    <div class="main" v-if="videoShow">
      <video controls autoplay muted style="width:70%">
        <source :src=url type="video/mp4">
      </video>
    </div>
  </van-dialog>

</template>

<script>
import _ from "lodash";


export default {
  name: "CourseVideo",

  data() {
    return {
      descriptionList: [],
      videoList:[],
      showDialog: false,
      videoShow: true,
      purposeReadOnly: false,
      isLoad: true,
      isHazardous: false,
      title: '',
      url: ''
    }
  },
  methods: {
    getCourseData() {
      var option = {
        portName: 'system.foundation.Dic.getDicDetail',
        data: {
          'code': 'COURSE_VIDEO'
        },
        async: true,
        needLoading: true,
        anonymous: true,
        successCallback: (data) => {
          let entity = data.entities[0];
          let bigContent = entity.bigContent;
          this.descriptionList =bigContent.split('\n')
          let dicAttach = entity.dicAttach;
          var compare = function (x, y) {
            if (x.fileName > y.fileName) {
              return 1
            } else if (x.fileName < y.fileName) {
              return -1;
            } else {
              return 0;
            }
          };
          if (!_.isEmpty(dicAttach)) {
            dicAttach.sort(compare);
          }
          this.videoList.push(...dicAttach);
        }
      };
      this.$sapi.callPort(option);
    },
    playVideo(item) {
      this.play(item.url, item.fileName);
    },
    play(url, title) {
      if (_.isEmpty(url)) {
        return;
      }
      this.url = url.replace('/tool/download', '/tool/downloadVideo');
      this.title = title;
      this.videoShow = true;
      this.showDialog = true;
    },
    onCloseVideo() {
      this.videoShow = false;
    },
  },
  created() {
    this.getCourseData();
  },
}
</script>

<style scoped>
html, body {
  width: 100%;
  margin: 0px;
  padding: 0px;
}

body {
  background-color: #ccc;
}

div, h3, img, dl, ul, li, dd, dt, p, h1, h2, h3, h4, h5, h6 {
  padding: 0px;
  margin: 0px;
}

img {
  border: none;
}

body {
  background-color: #fff;
  font-family: "Microsoft YaHei";

}

.zjIntro {
  text-align: center;
  padding: 2rem;
}

.logo {
  margin-bottom: 10px;
}

.intro {
  border: 2px solid #f00;
  font-size: 14px;
  margin-bottom: 20px;
  border-radius: 3px;
}

.intro h3 {
  background-color: #f00;
  color: #fff;
  padding: 3px 0px;
  font-size: 16px;
}

.introtiplist {
  text-align: left;
  margin: 10px 40px 20px 40px;
}

.introtiplist li {
  list-style: decimal;
  margin-bottom: 10px;
}

.inteoH1 {
  font-size: 16px;
  margin: 10px 0px;
}

.introList dt {
  background-color: #ddd;
  color: #000;
  padding: 3px 0px;
  font-size: 16px;
  margin-bottom: 10px;
  /*box-shadow: 0px 10px 10px rgba(0,0,0,0.2);*/
}

.introList dd {
  margin-bottom: 10px;
  padding: 0px 16px;
  text-align: left;
  font-size: 14px;
}

.introList {
  clear: both;
  margin: 0px auto;
  border: 2px solid #ddd;
  padding-bottom: 10px;
  border-radius: 3px;
}

.introList dd img {
  vertical-align: middle;
  height: 22px;
  margin-right: 5px;
}

.introList dd a {
  text-decoration: none;
  color: #0d6ac7;
}

.introList dd a:hover {
  text-decoration: none;
  color: #f30;
}



.video-js .vjs-tech {
  position: relative !important;
}

video{
  /*object-fit: fill;*/
}

.video-js, .main, .vjs-tech {
  width: 100%;
  height: 100%!important;
}
.main{
  text-align: center;
}

</style>